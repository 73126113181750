<template>
	<v-sheet style="height: calc(100vh - 90px); overflow: hidden">
		<!-- <SupportTopNav /> -->
		<v-container>
			<v-layout row wrap>
				<SupportSidebar />
				<SupportChat />
				<SupportChatDetails />
			</v-layout>
		</v-container>
	</v-sheet>
</template>
<script>
// import SupportTopNav from "@/view/module/support/SupportTopNav.vue";
import SupportSidebar from "@/view/module/support/SupportSidebar.vue";
import SupportChat from "@/view/module/support/SupportChat.vue";
import SupportChatDetails from "@/view/module/support/SupportChatDetails.vue";
export default {
	components: { SupportSidebar, SupportChat, SupportChatDetails },
	name: "project-support",
	title: "support Project",
};
</script>
