<template>
	<v-flex xs12 sm6 md4 lg3 class="pa-4 shadow-left" style="min-height: 100vh">
		<div class="d-flex justify-space-between">
			<v-tooltip top>
				<template v-slot:activator="{ on, attrs }">
					<v-btn elevation="0" v-bind="attrs" v-on="on">
						<v-icon>mdi-menu</v-icon><span class="px-2">Assigned To Me</span
						><v-chip color="blue-grey lighten-3" x-small>1</v-chip></v-btn
					>
				</template>
				<span>All Views(cltr+shift+u)</span>
			</v-tooltip>
			<div>
				<v-menu offset-y>
					<template v-slot:activator="{ on, attrs }">
						<v-btn depressed tile color="blue darken-4" v-bind="attrs" v-on="on" class="text-white">
							<v-icon color="white" small left>mdi-tune</v-icon>
						</v-btn>
					</template>
					<v-list dense class="p-0">
						<v-list-item dense>
							<v-checkbox dense label="First Response Due" v-model="firstResponseCheckbox"></v-checkbox>
						</v-list-item>
						<v-list-item dense>
							<v-checkbox dense label=" Response Due" v-model="responseCheckbox"></v-checkbox>
						</v-list-item>
						<v-list-item dense>
							<v-checkbox dense label=" Response Due" v-model="responseCheckbox"></v-checkbox>
						</v-list-item>
						<v-list-item dense class="cursor-disabled">
							<v-checkbox dense disabled label="Inactive" v-model="noResponseCheckbox"></v-checkbox>
						</v-list-item>
					</v-list>
				</v-menu>
				<v-menu offset-y color="blue-grey lighten-5 ">
					<template v-slot:activator="{ on, attrs }">
						<v-btn depressed tile color="blue darken-4" v-bind="attrs" v-on="on" class="text-white ml-4">
							<v-icon color="white" small left>mdi-sort</v-icon>
						</v-btn>
					</template>
					<v-list dense class="py-1">
						<v-list-item
							v-for="(item, index) of items"
							:key="index"
							dense
							:class="{ 'cyan lighten-5': index === 2 }"
							class="mx-2 sort-item py-1"
						>
							<v-list-item-title
								dense
								class="d-flex align-center text-body-2 mx-2 font-weight-regular font-weight-light"
							>
								{{ item.title }}
							</v-list-item-title>
						</v-list-item>
					</v-list>
				</v-menu>
			</div>
		</div>
		<v-divider class="mb-0 p-0"></v-divider>
		<div class="blue-grey lighten-5 px-4 py-2 d-flex justify-space-between">
			<span><v-icon color="black" small left>mdi-sort</v-icon>Longest wait time</span>
			<v-divider vertical></v-divider>
			<span>1 filtered conversations</span>
		</div>
		<div class="p-4 d-flex sidebar">
			<v-avatar size="36" color="red lighten-2">
				<span class="white--text text-h5">CJ</span>
			</v-avatar>
			<div class="d-flex flex-column ml-3 mr-4">
				<span class="blue-grey--text font-weight-medium darken-4">Jane Sampleton(Sample)</span>
				<v-icon class="my-1" style="width: 25px" size="18">mdi-camera</v-icon>
				<span> <v-icon color="grey darken-2" small left>mdi-calendar</v-icon>Chat With us</span>
			</div>
			<div class="d-flex flex-column justify-space-between mx-auto font-italic">
				<div>2 hour ago</div>

				<div class="d-flex justify-space-between">
					<v-icon>mdi-undo</v-icon>
					<v-tooltip top>
						<template v-slot:activator="{ on, attrs }">
							<v-avatar color="blue lighten-4" size="20" class="p-1" v-bind="attrs" v-on="on"
								><span class="blue--text lighten-1">M</span></v-avatar
							>
						</template>
						<span>mohd Uvesh</span>
					</v-tooltip>
				</div>
			</div>
		</div>
	</v-flex>
</template>
<script>
export default {
	name: "support-sidebar",
	title: "support Project",
	data: () => ({
		firstResponseCheckbox: "",
		responseCheckbox: "",
		noResponseCheckbox: "",
		items: [
			{ title: "newset Converstion (Team Inbox)" },
			{ title: "oldest Converstion" },
			{ title: "Longest wait time (Priority Inbox)" },
			{ title: "Due to time" },
		],
	}),
};
</script>

<style>
.cursor-disabled {
	cursor: not-allowed;
}
.sort-item {
	transition: all 0.4s ease-in-out;
	cursor: pointer;
}
.sort-item:hover {
	background-color: rgb(241, 244, 244);
}
.shadow-left {
	box-shadow: 0px 0px 10px 0 rgba(0, 0, 0, 0.1);
}
.v-messages {
	display: none;
}
.sidebar {
	cursor: pointer;
	/* background-color: #12344D; */
	transition: all 500ms ease-in-out;
	background-color: rgb(224, 248, 255);
}
.sidebar:hover {
	background-color: rgb(229, 255, 255);
}
</style>
